const articles = [
  {
    name: 'misi',
    title: 'Misi',
    desc: 'Mengapa menggunakan react dalam pembuatan website',
    img:
      'https://1.bp.blogspot.com/-cctI29Ia7jM/X-QXHOplqzI/AAAAAAAAMNw/A2IaXhMsWVYmnUEnfTQyvUKKnplm1_GZgCLcBGAsYHQ/s1000/aplikasi%2Bbisnis%2Binvoice%2Bakuntansi.jpg',
    content: [
      `React adalah framework modern dan dapat digunakan dalam proses pembuatan website khusus nya informasi menjadi lebih menarik dan tentunya akses kecepatan non load menyempurnakan dalam bekerja dengan react js.`,
      `Saat nya menggunakan react untuk berbagai proses pembuatan website informasi modern dengan desain yang unik dan menarik menjadikan lebih mewah elegan website dengan react js.`
    ]
  },
  {
    name: 'android',
    title: 'Android',
    desc: 'Pembuatan aplikasi android dengan react',
    img:
      'https://1.bp.blogspot.com/-O7iIhwxclXM/X-QXIQX4N_I/AAAAAAAAMN4/Yl7SUBw85OA4Fk1EyFd3IW7D3ATcmcNAwCLcBGAsYHQ/s1000/aplikasi%2Bkantor%2Bfaktur%2Bpenjualan.jpg',
    content: [
      `Dengan single page application react menjadi yang terbaik untuk digunakan dalam proses develope aplikasi android untuk digunakan.`,
      `Menggunakan web view adalah jalan ninja cepat dalam proses pembuatan aplikasi android berbasis react js, dengan non load akses setiap halaman maka cukup satu framework website dan aplikasi android akan terintergasi.`
    ]
  },
  {
    name: 'design',
    title: 'Desain',
    desc: 'Desain unik dan menarik untuk display website',
    img:
      'https://1.bp.blogspot.com/-eXysooc8tP8/X-MC99LYS2I/AAAAAAAAMD4/wQO1XZDgdcgCLkZxx4byL5JE9xkFQ4JfQCLcBGAsYHQ/s497/web_hosting.png',
    content: [
      `Dan pastinya dengan dukungan tema yang menarik dan unik dalam proses pembuatan website react semakin keren dan elegan dalam build modern situs untuk berbagai kebutuhan.`,
      `Convert template dari themes team cms website pun dapat dilakukan dengan layanan convert tema.`
    ]
  },
  {
    name: 'security',
    title: 'Security',
    desc: 'Website dengan tingkat security tinggi',
    img:
      'https://1.bp.blogspot.com/-_t509qsqLz8/X-IOO5-R7MI/AAAAAAAAL_0/nnYc00Fig6USqy6Hw4H9IGd80cwjgwg2wCLcBGAsYHQ/s581/aplikasi.png',
    content: [
      `Menggunakan react js dalam pengembangan dan pembuatan website akan semakin menarik hal ini dikarenakan tingkat keamanan yang tinggi , statis site generator website menjadi lebih baik akan kebutuhan implementasi web info dalam tujuan memberikan informasi secara detail via react site.`
    ]
  },
  {
    name: 'company',
    title: 'Company',
    desc: 'Pembuatan website company profile',
    img:
      'https://1.bp.blogspot.com/-z4D2YtC4Gjk/X-MC0kVNgFI/AAAAAAAAMDg/CFywfEixd60lHyKtsBbcCl-3wuPo9PXewCLcBGAsYHQ/s626/aplikasisekolah.jpg',
    content: [
      `Company profile merupakan salah satu penggerak ekonomi bisnis perusahaan dengan development yang tepat tampilan display yang elegan dan mewah maka membuat webiste company profile dengan react adalah prioritas utama dalam bekerja hal ini dikarenakan technology terbaru tersemat pada react js , tentunya dengan single page app menjadikan lebih baik untuk akses non load pada setiap halaman nya, dengan react sempurnakan pembuatan website company profile modern dengan technology terbaru.`
    ]
  },
  {
    name: 'sekolah',
    title: 'Sekolah',
    desc: 'Pembuatan website sekolah',
    img:
      'https://1.bp.blogspot.com/-CaGGNz-9VSk/X-MC0VL9YcI/AAAAAAAAMDc/3FvrjM18IuwZ5f7LRWJmRPuPc-L3eoZrwCLcBGAsYHQ/s626/apl.jpg',
    content: [
      `Hey saat ini sangat jarang kita lihat sekolah edukasi menggunakan react dalam proses development nya, dan kini saat nya untuk sekolah anda menggunakan react dalam proses pembuatan website sekolah, sensasi non loading akses cepat pada setiap page nya hingga kebutuhan untuk memberikan informasi mengenai profile, visi misi , sejarah, gallery, dan info PPDB online maupun lain nya dapat dengan cepat diakses via react site sekolah .`,
      `Dengan menggunakan react js maka sekolah selangkah lebih maju dalam hal proses pembuatan website modern.`
    ]
  },
  {
    name: 'restoran',
    title: 'Kuliner',
    desc: 'Pembuatan website restoran cafe rumah makan.',
    img:
      'https://1.bp.blogspot.com/-CdSt04cw98Y/X-MC0fuTTyI/AAAAAAAAMDY/ghskpmEDFBwMikhCvFK5MKRvVLu1w9F1wCLcBGAsYHQ/s626/apps.webp',
    content: [
      `Dengan menggunakan react untuk proses pembuatan website restoran cafe rumah makan maka akan semakin elegan dan mewah untuk akses pelanggan, dengan kecepatan tanpa batas, page non load pada setiap akses nya membuat pelanggan merasa nyaman mengunjungi website restoran cafe rumah makan bukan hanya itu saja dengan kemampuan online order akan menjadi lebih baik untuk menerima proses pesanan pelanggan via whatsapp secara langsung.`
    ]
  },

  {
    name: 'shop',
    title: 'Shop',
    desc: 'Pembuatan website toko shop and others',
    img:
      'https://1.bp.blogspot.com/-4SJl3zxEmI8/X-MC7eawLPI/AAAAAAAAMDk/H_5XbaABIowyhxS547xBFZ3rP6o0rSOsgCLcBGAsYHQ/s626/APK%2Bandroid%2Bweb%2Bsekolah.jpg',
    content: [
      `Semakin sempurna dengan pembuatan website toko sebagai profile dan informasi menggunakan react ini, bukan tanpa sebab kecepatan tanpa batas akses non loading pada setiap halaman nya membuat pelanggan semakin nyaman dalam kebutuhan mencari informasi produk yang dijual pada toko , dan tentunya via whastapp order menjadikan proses closing pelanggan semakin cepat, dengan react saat nya kini update website modern mu .`
    ]
  },

  {
    name: 'konsep',
    title: 'Konsep',
    desc: 'Konsep pembuatan website',
    img:
      'https://1.bp.blogspot.com/-r-wSGgqtsm0/X-Hr184zSbI/AAAAAAAAL-8/I377EooVg4gg4PMyPfh4PBIcKU0Kf83zQCLcBGAsYHQ/s625/pembuatan%2Bwebsite%2Bcompany%2Bprofile%2B%25283%2529.png',
    content: [
      `Tentunya dengan struktur dan skema konsep yang disesuaikan dengan berbagai bidang untuk proses pembuatan website ini, baik untuk sekolah, company profile, restoran , toko cafe dan berbagai bidang usaha lain nya, semakin lebih baik dengan konsep yang terstruktur secara skema untuk proses delpoy ke cloud host .`
    ]
  },

  {
    name: 'portfolio',
    title: 'Portfolio',
    desc: 'Portfolio project kami',
    img:
      'https://1.bp.blogspot.com/-P87ExqhdGps/X-Hr04pLjqI/AAAAAAAAL-0/M3Vv_gcxMBM9qMzFWqXM6aRtzb8_MTa5QCLcBGAsYHQ/s624/pembuatan%2Bwebsite%2Bcompany%2Bprofile%2B%25281%2529.png',
    content: [
      `Hey.. kami juga berkontribusi pada dunia open source code, banyak project kami yang kami upload pada halaman source kami, baik via sourceforge maupun github repo, berbagai aplikasi telah kami ciptakan untuk dapat digunakan dan berguna bagi dunia via github anda dapat mengunjungi https://github.com/mesinkasir atau via sourceforge list project kami https://sourceforge.net/u/chromers/profile.`
    ]
  },

  {
    name: 'freedom',
    title: 'Freedom',
    desc: 'Kebebasan bersama kami',
    img:
      'https://1.bp.blogspot.com/-fm954TepweM/X-Hr19oKxLI/AAAAAAAAL-4/sh8efNGxAUc-IPdn-pqM1dVxlBaxolEPwCLcBGAsYHQ/s1000/pembuatan%2Bwebsite%2Bcompany%2Bprofile%2B%25282%2529.png',
    content: [
      `Temukan kebebasan tanpa batas bersama team developer kami , untuk kebutuhan pembuatan website maupun aplikasi semua semakin mudah untuk mewujudkan impian anda, atau gunakan layanan kami full untuk yang terbaik dalam pengembangan aplikasi maupun website yang kami skema kan agar secara penuh benar benar bermanfaat dalam mendukung usaha anda.`
    ]
  }
];

export default articles;
