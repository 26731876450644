import React from 'react';
import Nav from './Nav';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
const ArticleList = ({ articles }) => (
  <>
    <Helmet>
  <meta name="google-site-verification" content="WH5yK5pEky5qND2FOW3_hvm3VlPkjRtV731y81BcsTc" />
      <title>Pembuatan website react</title>
      <meta
        name="description"
        content="jasa pembuatan website company profile, sekolah, restoran cafe rumah makan, toko , art work dengan react"
      />
      <meta content="Pembuatan website react" property="og:title" />
      <meta
        content="jasa pembuatan website company profile, sekolah, restoran cafe rumah makan, toko , art work dengan react"
        property="og:description"
      />
      <meta
        content="https://1.bp.blogspot.com/-t2VnKnlI0l8/Xzt04NUjFAI/AAAAAAAALN8/TUprEcTdVlwmkL3a1sXnbtDcGHUv-3kxQCLcBGAsYHQ/s1200/aplikasi%2Btoko%2Bmesin%2Bkasir%2Bonline%2Blaravel%2Bpoint%2Bof%2Bsale%2Bcashier%2Bapps%2B%25283%2529.jpg"
        property="og:image"
      />
      <meta content="https://axcora.com/getaxcoracms" property="og:author" />
      <meta content="Pembuatan website react" name="facebook:title" />
      <meta content="website" property="og:type" />
      <meta
        content="https://www.facebook.com/mesinkasircomplete"
        property="facebook:author"
      />
      <meta
        content="https://1.bp.blogspot.com/-t2VnKnlI0l8/Xzt04NUjFAI/AAAAAAAALN8/TUprEcTdVlwmkL3a1sXnbtDcGHUv-3kxQCLcBGAsYHQ/s1200/aplikasi%2Btoko%2Bmesin%2Bkasir%2Bonline%2Blaravel%2Bpoint%2Bof%2Bsale%2Bcashier%2Bapps%2B%25283%2529.jpg"
        name="facebook:image"
      />
      <meta content="Pembuatan website react" property="og:site_name" />
      <meta
        content="jasa pembuatan website company profile, sekolah, restoran cafe rumah makan, toko , art work dengan react"
        property="facebook:description"
      />
      <meta content="summary_large_image" name="twitter:card" />
      <meta
        content="https://twitter.com/hockeycomp"
        property="twitter:author"
      />
      <meta content="@hockeycomp" name="twitter:site" />
      <meta
        content="https://1.bp.blogspot.com/-t2VnKnlI0l8/Xzt04NUjFAI/AAAAAAAALN8/TUprEcTdVlwmkL3a1sXnbtDcGHUv-3kxQCLcBGAsYHQ/s1200/aplikasi%2Btoko%2Bmesin%2Bkasir%2Bonline%2Blaravel%2Bpoint%2Bof%2Bsale%2Bcashier%2Bapps%2B%25283%2529.jpg"
        name="twitter:image"
      />
      <meta
        content="jasa pembuatan website company profile, sekolah, restoran cafe rumah makan, toko , art work dengan react"
        name="twitter:description"
      />
      <meta content="Pembuatan website react" name="twitter:title" />
      <meta content="index, follow" name="robots" />
    </Helmet>
    <Nav />
    <div
      className="uk-child-width-1-2 uk-child-width-1-3@s uk-grid-match uk-grid-small neumorphism-outer"
      uk-grid
    >
      {articles.map((article, key) => (
        <Link
          className="uk-animation-slide-top btn btn-outline-primary border border-dark p-3 p-md-3 col-12 col-md-3"
          tabindex="0"
          key={key}
          to={`/article/${article.name}`}
        >
          {' '}
          <div class="uk-text-center">
            <div className="uk-inline-clip uk-transition-toggle">
              <img className="img-fluid rounded bg-white" src={article.img} />
              <div class="uk-transition-slide-bottom uk-position-bottom uk-overlay uk-overlay-default">
                <h3 className="text-secondary">
                  <strong>{article.title}</strong>
                </h3>
                <p className="text-dark">{article.desc}</p>
              </div>
            </div>
          </div>
        </Link>
      ))}
    </div>
  </>
);

export default ArticleList;
